import Glide from '@glidejs/glide';

let glide = new Glide('.glide-store-slidy', {
  type: 'carousel',
  autoplay: 14000,
  perView: 1.15,
  focusAt: 'center',
});
glide.mount();

let glideMobile = new Glide('.glide-store-mobile-slidy', {
  type: 'carousel',
  autoplay: 14000,
  perView: 1,
  focusAt: 'center',
});
glideMobile.mount();